import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Button, Link } from '@mui/material';

// =============================|| LANDING - FEATURE PAGE ||============================= //

const HowWeCanHelpSection = ({ handleApply }) => {
    const theme = useTheme();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">How We Can Help</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ mt: 2, mb: 2 }} variant="subtitle1">
                    Beginning in 2024, we are launching an accelerator program dedicated to helping women and under-represented founders
                    prepare for their first external financing.
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ pr: { xs: 0, md: 2 } }}>
                <Box
                    component="img"
                    sx={{
                        maxWidth: { xs: '100%' },
                        borderRadius: 0.5
                    }}
                    src="https://mentorsplusangels-data.s3.amazonaws.com/images/headway-HOME-help.jpg"
                />
            </Grid>

            <Grid item xs={12} md={8} sx={{ pl: { xs: 0, md: 2 } }}>
                <Typography>
                    The{' '}
                    <Link component={RouterLink} to="/program" color="secondary" underline="hover">
                        m+a accelerator Program
                    </Link>
                    , will feature a series of lectures and workshops (encompassing both hard and soft skills) where founding teams will
                    learn how to position themselves in the best light to attract external investors.
                </Typography>
                <Typography sx={{ mt: 2, mb: 2 }}>
                    Companies will also be assigned mentors from the m+a team who have experience in the relevant market sector or
                    discipline and can provide advice on early stage business fundamentals.
                </Typography>
                <Typography variant="h4" color={theme.palette.grey[500]}>
                    Join the m+a accelerator to help secure your first external financing
                </Typography>

                <Grid container justifyContent="flex-end" sx={{ mt: 1 }}>
                    <Grid item>
                        <Button variant="contained" color="warning" onClick={handleApply}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

HowWeCanHelpSection.propTypes = {
    handleApply: PropTypes.func
};

export default HowWeCanHelpSection;
