import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Step, StepLabel, Stepper } from '@mui/material';

// Project imports
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { formValidation } from './validation';

const steps = ['Contact', 'Company', 'Business', 'Files', 'Submission'];

// ==============================|| CREATE APPLICATION POPUP ||============================== //

const CreateApplicationPopUp = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({ progID: '0' });

    const updateData = (data) => {
        // Remove error if present
        if (errors.length > 0) {
            let newErrors = [];
            Object.keys(data).forEach((key) => {
                newErrors = errors.filter((item) => item.field !== key);
            });
            setErrors(newErrors);
            if (newErrors.length === 0) {
                setErrorMessage('');
            }
        }
        setFormData({ ...formData, ...data });
    };

    const resetError = (field) => {
        const updatedErrors = [...errors.filter((item) => item.field !== field)];
        setErrors(updatedErrors);
        if (updatedErrors.length === 0) {
            setErrorMessage('');
        }
    };

    const getForm = (step) => {
        switch (step) {
            case 0:
                return <Step1 updateData={updateData} formData={formData} errors={errors} resetError={resetError} />;
            case 1:
                return <Step2 updateData={updateData} formData={formData} errors={errors} resetError={resetError} />;
            case 2:
                return <Step3 updateData={updateData} formData={formData} errors={errors} resetError={resetError} />;
            case 3:
                return <Step4 updateData={updateData} formData={formData} errors={errors} resetError={resetError} />;
            case 4:
                return <Step5 updateData={updateData} formData={formData} errors={errors} resetError={resetError} />;
            default:
                throw new Error(`Unknown step: ${step}`);
        }
    };

    const clickNext = () => {
        if (activeStep >= steps.length - 1) return;

        const formErrors = formValidation({ activeStep, formData });
        setErrors(formErrors);
        if (formErrors.length > 0) {
            setErrorMessage('Please correct the highlighted applications');
            return;
        }
        setErrorMessage('');
        setActiveStep(activeStep + 1);
    };

    const clickBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const errorsOnPage = () => {
        const { urAgreed } = formData;
        if (!urAgreed) return true;
        return false;
    };

    const handleSubmit = () => {
        const formErrors = formValidation({ activeStep, formData });
        setErrors(formErrors);
        if (formErrors.length > 0) {
            setErrorMessage('Please correct the highlighted applications');
            return;
        }
        setErrorMessage('');

        handleClose({ save: true, application: formData });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>Application Form: m+a accelerator</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {getForm(activeStep)}
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage && (
                            <Typography variant="h4" color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose({ save: false })} variant="outlined" size="small" color="secondary" sx={{ mr: 2 }}>
                    Cancel
                </Button>
                {activeStep > 0 && (
                    <Button onClick={clickBack} variant="contained" size="small" color="secondary" sx={{ mr: 2 }}>
                        Back
                    </Button>
                )}
                {activeStep < steps.length - 1 && (
                    <Button onClick={clickNext} variant="contained" size="small" sx={{ mr: 2 }} disabled={errorsOnPage()}>
                        Next
                    </Button>
                )}
                {activeStep === steps.length - 1 && (
                    <Button onClick={handleSubmit} variant="contained" size="small" color="warning" sx={{ mr: 2 }}>
                        Submit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

CreateApplicationPopUp.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default CreateApplicationPopUp;
