import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';

// Project imports
import { getErrorText, errorExists } from 'utils/errors';

// ==============================|| APPLICATION FORM - STEP 4  ||============================== //

const Step1 = ({ formData, resetError, updateData, errors, isReadOnly }) => {
    const { urAgreed = '', linkedInLink = '', familyName = '', givenName = '', email = '', phone = '', jobTitle = '' } = formData;

    console.log('formData', formData);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography>
                    The program is aimed at “under-represented” founders (e.g. women, people of color, physically impaired, veteran or
                    socially disadvantaged). Applications will be evaluated with this as a primary consideration.
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!urAgreed}
                            onChange={(e) => {
                                updateData({ urAgreed: e.target.checked });
                            }}
                            name="checked"
                            color="primary"
                            disabled={isReadOnly}
                        />
                    }
                    label={<Typography variant="subtitle1">I understand</Typography>}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">First we need to know your contact information</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    fullWidth
                    label="First Name"
                    value={givenName}
                    onChange={(e) => {
                        updateData({ givenName: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('givenName')}
                    helperText={getErrorText({ errors, field: 'givenName' })}
                    error={errorExists({ errors, field: 'givenName' })}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    fullWidth
                    label="Last Name"
                    value={familyName}
                    onChange={(e) => {
                        updateData({ familyName: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('familyName')}
                    helperText={getErrorText({ errors, field: 'familyName' })}
                    error={errorExists({ errors, field: 'familyName' })}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    fullWidth
                    label="Email address"
                    value={email}
                    onChange={(e) => {
                        updateData({ email: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('email')}
                    helperText={getErrorText({ errors, field: 'email' })}
                    error={errorExists({ errors, field: 'email' })}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    fullWidth
                    label="Phone Number"
                    value={phone}
                    onChange={(e) => {
                        updateData({ phone: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('phone')}
                    helperText={getErrorText({ errors, field: 'phone' })}
                    error={errorExists({ errors, field: 'phone' })}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Job Title"
                    value={jobTitle}
                    onChange={(e) => {
                        updateData({ jobTitle: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('jobTitle')}
                    helperText={getErrorText({ errors, field: 'jobTitle' })}
                    error={errorExists({ errors, field: 'jobTitle' })}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="linkedInLink"
                    label="linkedIn Link"
                    placeholder="linkedIn Link"
                    value={linkedInLink}
                    fullWidth
                    onChange={(e) => updateData({ linkedInLink: e.target.value })}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'linkedInLink' })}
                    error={errorExists({ errors, field: 'linkedInLink' })}
                    onFocus={() => resetError('linkedInLink')}
                />
            </Grid>
        </Grid>
    );
};

Step1.propTypes = {
    formData: PropTypes.object,
    resetError: PropTypes.func,
    updateData: PropTypes.func,
    errors: PropTypes.array,
    isReadOnly: PropTypes.bool
};

export default Step1;
