import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, TextField, FormControlLabel, Checkbox, Link } from '@mui/material';

// Project imports
import { getErrorText, errorExists } from 'utils/errors';

// ==============================|| APPLICATION FORM - STEP 4  ||============================== //

const Step5 = ({ formData, resetError, updateData, errors, isReadOnly }) => {
    const { whyUs, referredBy, usBased, termsAgreed, participationAgreed } = formData;

    console.log('formData', formData);
    console.log('errors', errors);

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <Typography variant="h4">You&apos;re almost done! Please complete the following and then hit &quot;Submit&quot;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Please let us know why you believe that your company should be part of the m+a accelerator</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Why give us the opportunity?"
                    value={whyUs}
                    onChange={(e) => {
                        updateData({ whyUs: e.target.value });
                    }}
                    onFocus={() => resetError('whyUs')}
                    helperText={getErrorText({ errors, field: 'whyUs' })}
                    error={errorExists({ errors, field: 'whyUs' })}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography>Finally, if you referred to us by an individual or an organization , please let us know here:</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Referred By"
                    value={referredBy}
                    onChange={(e) => {
                        updateData({ referredBy: e.target.value });
                    }}
                    onFocus={() => resetError('referredBy')}
                    helperText={getErrorText({ errors, field: 'referredBy' })}
                    error={errorExists({ errors, field: 'referredBy' })}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!usBased}
                                    onChange={(e) => updateData({ usBased: e.target.checked })}
                                    name="checked"
                                    color="primary"
                                    disabled={isReadOnly}
                                />
                            }
                            label={<Typography variant="subtitle1">I confirm that we are US based</Typography>}
                        />
                        {errorExists({ errors, field: 'usBased' }) && (
                            <Typography color="error">{getErrorText({ errors, field: 'usBased' })}</Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!participationAgreed}
                                    onChange={(e) => updateData({ participationAgreed: e.target.checked })}
                                    name="checked"
                                    color="primary"
                                    disabled={isReadOnly}
                                />
                            }
                            label={
                                <Typography variant="subtitle1">
                                    I confirm that I (and my team, if any) am/are available to participate for the duration of the program
                                    (6-10h/week, for six weeks, starting April 3rd, 2024)
                                </Typography>
                            }
                        />
                    </Grid>
                    {errorExists({ errors, field: 'participationAgreed' }) && (
                        <Typography color="error">{getErrorText({ errors, field: 'participationAgreed' })}</Typography>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!termsAgreed}
                                    onChange={(e) => updateData({ termsAgreed: e.target.checked })}
                                    name="checked"
                                    color="primary"
                                    disabled={isReadOnly}
                                />
                            }
                            label={
                                <Typography variant="subtitle1">
                                    I understand that there is no charge for the program, and I agree with the &nbsp;
                                    <Link href="https://www.mentorsplusangels.com/terms/" target="_blank">
                                        Terms & Conditions
                                    </Link>
                                </Typography>
                            }
                        />
                    </Grid>
                    {errorExists({ errors, field: 'termsAgreed' }) && (
                        <Typography color="error">{getErrorText({ errors, field: 'termsAgreed' })}</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

Step5.propTypes = {
    formData: PropTypes.object,
    resetError: PropTypes.func,
    updateData: PropTypes.func,
    errors: PropTypes.array,
    isReadOnly: PropTypes.bool
};

export default Step5;
