import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// material-ui
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Link,
    Stack,
    Typography
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const LinkedInWrapper = styled(Button)({
    padding: 8,
    background: 'rgba(14, 118, 168, 0.12)',
    '& svg': {
        color: '#0E76A8'
    },
    '&:hover': {
        background: '#0E76A8',
        '& svg': {
            color: '#fff'
        }
    }
});

// ==============================|| TEAM MEMBER POPUP ||============================== //

const TeamMemberPopUp = ({ open, person, handleClose }) => {
    const { title, avatar, linkedIn, name, bio = [] } = person || {};

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>
                <Grid container>
                    {bio.map((line) => (
                        <Grid item xs={12}>
                            <Typography sx={{ mt: 1 }}>{line}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LinkedInWrapper target="_blank" component="a" href={linkedIn}>
                    <LinkedInIcon />
                </LinkedInWrapper>
                <Button sx={{ mr: 2 }} variant="contained" size="small" onClick={() => handleClose(true)}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TeamMemberPopUp.propTypes = {
    open: PropTypes.bool,
    person: PropTypes.object,
    handleClose: PropTypes.func
};

export default TeamMemberPopUp;
