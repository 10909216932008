import { useRef } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, IconButton, Stack } from '@mui/material';
import { IconBookUpload, IconBook2, IconEye } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';

// Project imports
import { getErrorText, errorExists } from 'utils/errors';
import { getFileDownloadURLAPI } from 'APIs/file';

// ==============================|| APPLICATION FORM - STEP 4  ||============================== //

const Step4 = ({ formData, updateData, errors, isReadOnly }) => {
    const uploadDeckRef = useRef(null);
    const uploadLogoRef = useRef(null);
    const uploadVideoRef = useRef(null);
    const theme = useTheme();

    const { pitchDeck, pitchDeckURL = '', pitchVideo, pitchVideoURL = '', logo, logoURL = '' } = formData;

    console.log('formData', formData);

    const displayUploadedfile = async (url) => {
        const signedURL = await getFileDownloadURLAPI(url);
        window.open(signedURL, '_blank')?.focus();
    };

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Next, please upload your current pitch deck and company logo, together with a video, if you have one
                </Typography>
                <Typography variant="caption">(We recommend limiting file sizes to 10Mb)</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                    <Grid item sx={{ mx: 2 }}>
                        <input
                            ref={uploadDeckRef}
                            type="file"
                            accept="application/pdf, application/vnd.ms-powerpoint"
                            style={{ display: 'none' }}
                            onChange={(e) => updateData({ pitchDeck: e.target.files[0] })}
                        />
                        <IconButton
                            sx={{
                                color: theme.palette.warning.dark,
                                ...((pitchDeck || pitchDeckURL) && { color: theme.palette.success.dark })
                            }}
                            onClick={() => uploadDeckRef.current && uploadDeckRef.current.click()}
                            disabled={isReadOnly}
                        >
                            {pitchDeck || pitchDeckURL ? <IconBook2 /> : <IconBookUpload />}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {pitchDeck || pitchDeckURL ? (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography sx={{ color: theme.palette.success.dark }}>
                                    {pitchDeckURL ? 'Pitch Deck Uploaded' : 'Pitch Deck Selected'}
                                </Typography>
                                {pitchDeckURL && (
                                    <IconButton
                                        onClick={() => displayUploadedfile(pitchDeckURL)}
                                        sx={{ color: theme.palette.secondary.main }}
                                    >
                                        <IconEye />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (
                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                {isReadOnly ? 'Not Uploaded' : 'Upload Pitch Deck'}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                {errorExists({ errors, field: 'pitchDeck' }) && (
                    <Typography color="error">{getErrorText({ errors, field: 'pitchDeck' })}</Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                    <Grid item sx={{ mx: 2 }}>
                        <input
                            ref={uploadLogoRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => updateData({ logo: e.target.files[0] })}
                        />
                        <IconButton
                            sx={{
                                color: theme.palette.warning.dark,
                                ...((logo || logoURL) && { color: theme.palette.success.dark })
                            }}
                            onClick={() => uploadLogoRef.current && uploadLogoRef.current.click()}
                            disabled={isReadOnly}
                        >
                            {logo || logoURL ? <IconBook2 /> : <IconBookUpload />}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {logo || logoURL ? (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography sx={{ color: theme.palette.success.dark }}>
                                    {logoURL ? 'Logo Uploaded' : 'Logo Selected'}
                                </Typography>
                                {logoURL && (
                                    <IconButton onClick={() => displayUploadedfile(logoURL)} sx={{ color: theme.palette.secondary.main }}>
                                        <IconEye />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (
                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                {/* {isReadOnly ? 'Not Uploaded' : 'Upload Logo (optional)'} */}
                                {isReadOnly ? (
                                    'Not Uploaded'
                                ) : (
                                    <>
                                        <Typography>Upload Logo</Typography>
                                        <Typography variant="caption">(optional)</Typography>
                                    </>
                                )}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                {errorExists({ errors, field: 'logo' }) && <Typography color="error">{getErrorText({ errors, field: 'logo' })}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                    <Grid item sx={{ mx: 2 }}>
                        <input
                            ref={uploadVideoRef}
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            style={{ display: 'none' }}
                            onChange={(e) => updateData({ pitchVideo: e.target.files[0] })}
                        />
                        <IconButton
                            sx={{
                                color: theme.palette.warning.dark,
                                ...((pitchVideo || pitchVideoURL) && { color: theme.palette.success.dark })
                            }}
                            onClick={() => uploadVideoRef.current && uploadVideoRef.current.click()}
                            disabled={isReadOnly}
                        >
                            {pitchVideo || pitchVideoURL ? <IconBook2 /> : <IconBookUpload />}
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {pitchVideo || pitchVideoURL ? (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography sx={{ color: theme.palette.success.dark }}>
                                    {pitchVideoURL ? 'Pitch Video Uploaded' : 'Pitch Video Selected'}
                                </Typography>
                                {pitchVideoURL && (
                                    <IconButton
                                        onClick={() => displayUploadedfile(pitchVideoURL)}
                                        sx={{ color: theme.palette.secondary.main }}
                                    >
                                        <IconEye />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (
                            <Typography sx={{ color: theme.palette.primary[200] }}>
                                {isReadOnly ? (
                                    'Not Uploaded'
                                ) : (
                                    <>
                                        <Typography>Upload Pitch Video</Typography>
                                        <Typography variant="caption">(optional)</Typography>
                                    </>
                                )}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Step4.propTypes = {
    formData: PropTypes.object,
    updateData: PropTypes.func,
    errors: PropTypes.array,
    isReadOnly: PropTypes.bool
};

export default Step4;
