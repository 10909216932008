import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';

// assets
import { IconBuildingBank, IconHome2, IconListCheck, IconSchool, IconUsers } from '@tabler/icons';
import MenuIcon from '@mui/icons-material/Menu';

// elevation scroll

function ElevationScroll({ children, window }) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    });

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.dark,
            borderBottom: trigger ? 'none' : '1px solid',
            // borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ handleApply, ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [drawerToggle, setDrawerToggle] = React.useState(false);
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };
    const buttonColor = theme.palette.primary.light;
    // debugger; // eslint-disable-line no-debugger

    return (
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar>
                        <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                            <Link component={RouterLink} to="/">
                                <Logo />
                            </Link>
                        </Typography>
                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}>
                            <Button sx={{ color: buttonColor }} onClick={() => navigate('/program')}>
                                Our accelerator
                            </Button>
                            <Button sx={{ color: buttonColor }} onClick={() => navigate('/team')}>
                                Our Team
                            </Button>
                            <Button sx={{ color: buttonColor }} onClick={() => navigate('/portfolio')}>
                                Portfolio
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ borderColor: theme.palette.warning.main, color: theme.palette.warning.main }}
                                onClick={handleApply}
                            >
                                Apply Now
                            </Button>
                        </Stack>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton onClick={drawerToggler(true)} size="large" sx={{ color: buttonColor }}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                                {drawerToggle && (
                                    <Box
                                        sx={{ width: 'auto' }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/">
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconHome2 />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Home" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/program">
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconSchool />
                                                    </ListItemIcon>
                                                    <ListItemText primary="The Program" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/team">
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconUsers />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Our Team" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/portfolio">
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconBuildingBank />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Portfolio" />
                                                </ListItemButton>
                                            </Link>
                                            <ListItemButton onClick={handleApply}>
                                                <ListItemIcon>
                                                    <IconListCheck />
                                                </ListItemIcon>
                                                <ListItemText primary="Apply Now" />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default AppBar;
