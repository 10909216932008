import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, TextField, MenuItem } from '@mui/material';

// Project imports
import { getErrorText, errorExists } from 'utils/errors';
import { legalStructureList } from 'config';
import { formatCurrency } from 'utils/format';

// ==============================|| APPLICATION FORM - STEP 2  ||============================== //

const Step2 = ({ formData, resetError, updateData, errors, isReadOnly }) => {
    const { companyName, address, websiteURL, fullTime, partTime, advisors, legalStructure = '', funding = 0 } = formData;

    console.log('formData', formData);

    const updateWebsite = (e) => {
        let urlValue = e.target.value.toLowerCase();
        if (urlValue.substring(0, 3) === 'www') {
            urlValue = `https://${urlValue}`;
        }
        updateData({ websiteURL: urlValue });
    };

    const updateCurrency = ({ value }) => {
        const stringValue = value.replace('$', '').replaceAll(',', '');
        const nunericValue = parseInt(stringValue, 10);
        updateData({ funding: nunericValue });
    };

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <Typography variant="h4">Next tell us a little about your company</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    fullWidth
                    label="Company Name"
                    value={companyName}
                    onChange={(e) => {
                        updateData({ companyName: e.target.value });
                    }}
                    disabled={isReadOnly}
                    onFocus={() => resetError('companyName')}
                    helperText={getErrorText({ errors, field: 'companyName' })}
                    error={errorExists({ errors, field: 'companyName' })}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Website"
                    value={websiteURL}
                    type="url"
                    onChange={updateWebsite}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'websiteURL' })}
                    error={errorExists({ errors, field: 'websiteURL' })}
                    onFocus={() => resetError('websiteURL')}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Where Based"
                    placeholder="Please give the city & state where you are based"
                    value={address}
                    onChange={(e) => {
                        updateData({ address: e.target.value });
                    }}
                    onFocus={() => resetError('address')}
                    helperText={getErrorText({ errors, field: 'address' })}
                    error={errorExists({ errors, field: 'address' })}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    value={legalStructure}
                    select
                    placeholder="Legal Structure"
                    label="Legal Structure"
                    onChange={(e) => {
                        updateData({ legalStructure: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'legalStructure' })}
                    error={errorExists({ errors, field: 'legalStructure' })}
                    onFocus={() => resetError('legalStructure')}
                >
                    {legalStructureList.map((type) => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <Typography>Please tell us how many people are employed by the company:</Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Full Time Employees"
                    type="number"
                    value={fullTime}
                    onChange={(e) => {
                        updateData({ fullTime: e.target.value });
                    }}
                    onFocus={() => resetError('fullTime')}
                    helperText={getErrorText({ errors, field: 'fullTime' })}
                    error={errorExists({ errors, field: 'fullTime' })}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Part Time Employees"
                    type="number"
                    value={partTime}
                    onChange={(e) => {
                        updateData({ partTime: e.target.value });
                    }}
                    onFocus={() => resetError('partTime')}
                    helperText={getErrorText({ errors, field: 'partTime' })}
                    error={errorExists({ errors, field: 'partTime' })}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Advisors"
                    type="number"
                    value={advisors}
                    onChange={(e) => {
                        updateData({ advisors: e.target.value });
                    }}
                    onFocus={() => resetError('advisors')}
                    helperText={getErrorText({ errors, field: 'advisors' })}
                    error={errorExists({ errors, field: 'advisors' })}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography>How much money has been put into the business? (both external and internal funding)</Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Funding"
                    // type="number"
                    value={formatCurrency(funding)}
                    onChange={(e) => {
                        updateCurrency({ value: e.target.value, field: 'funding' });
                    }}
                    onFocus={() => resetError('funding')}
                    helperText={getErrorText({ errors, field: 'funding' })}
                    error={errorExists({ errors, field: 'funding' })}
                />
            </Grid>
        </Grid>
    );
};

Step2.propTypes = {
    formData: PropTypes.object,
    resetError: PropTypes.func,
    updateData: PropTypes.func,
    errors: PropTypes.array,
    isReadOnly: PropTypes.bool
};

export default Step2;
