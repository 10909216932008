import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, TextField, MenuItem } from '@mui/material';

// Project imports
import { getErrorText, errorExists } from 'utils/errors';
import { industryList, busTechList, stageList, tractionList, addressableMarketList } from 'config';

// ==============================|| APPLICATION FORM - STEP 3  ||============================== //

const Step3 = ({ formData, resetError, updateData, errors, isReadOnly }) => {
    const { addMarket, traction, industry, busTech, oneLiner, businessModel, devStage } = formData;

    console.log('formData', formData);

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <Typography variant="h4">Now, please tell us about your business</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>In one sentence please tell us what your company does</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="One Liner"
                    value={oneLiner}
                    onChange={(e) => {
                        updateData({ oneLiner: e.target.value });
                    }}
                    onFocus={() => resetError('oneLiner')}
                    helperText={getErrorText({ errors, field: 'oneLiner' })}
                    error={errorExists({ errors, field: 'oneLiner' })}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography>Please describe your business model. How will you make money?</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Business Model"
                    value={businessModel}
                    onChange={(e) => {
                        updateData({ businessModel: e.target.value });
                    }}
                    onFocus={() => resetError('businessModel')}
                    helperText={getErrorText({ errors, field: 'businessModel' })}
                    error={errorExists({ errors, field: 'businessModel' })}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography>Please use the following to categorize your company</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    value={industry}
                    select
                    placeholder="Industry"
                    label="Industry"
                    onChange={(e) => {
                        updateData({ industry: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'industry' })}
                    error={errorExists({ errors, field: 'industry' })}
                    onFocus={() => resetError('industry')}
                >
                    {industryList.map((industry) => (
                        <MenuItem key={industry} value={industry}>
                            {industry}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    value={busTech}
                    select
                    placeholder="Business/Technology"
                    label="Business/Technology"
                    onChange={(e) => {
                        updateData({ busTech: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'busTech' })}
                    error={errorExists({ errors, field: 'busTech' })}
                    onFocus={() => resetError('busTech')}
                >
                    {busTechList.map((cat) => (
                        <MenuItem key={cat} value={cat}>
                            {cat}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Typography>Please use the following to tells about your product and market</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    value={devStage}
                    select
                    placeholder="Stage of Development"
                    label="Stage of Development"
                    onChange={(e) => {
                        updateData({ devStage: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'devStage' })}
                    error={errorExists({ errors, field: 'devStage' })}
                    onFocus={() => resetError('devStage')}
                >
                    {stageList.map((stage) => (
                        <MenuItem key={stage} value={stage}>
                            {stage}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    value={addMarket}
                    select
                    placeholder="Size of Addressable Market"
                    label="Size of Addressable Market"
                    onChange={(e) => {
                        updateData({ addMarket: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'addMarket' })}
                    error={errorExists({ errors, field: 'addMarket' })}
                    onFocus={() => resetError('addMarket')}
                >
                    {addressableMarketList.map((mktSize) => (
                        <MenuItem key={mktSize} value={mktSize}>
                            {mktSize}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    value={traction}
                    select
                    placeholder="Market Traction"
                    label="Market Traction"
                    onChange={(e) => {
                        updateData({ traction: e.target.value });
                    }}
                    disabled={isReadOnly}
                    helperText={getErrorText({ errors, field: 'traction' })}
                    error={errorExists({ errors, field: 'traction' })}
                    onFocus={() => resetError('traction')}
                >
                    {tractionList.map((tractionName) => (
                        <MenuItem key={tractionName} value={tractionName}>
                            {tractionName}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
};

Step3.propTypes = {
    formData: PropTypes.object,
    resetError: PropTypes.func,
    updateData: PropTypes.func,
    errors: PropTypes.array,
    isReadOnly: PropTypes.bool
};

export default Step3;
